.contact-in {
    margin-top: 10rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    background: #F5EBE0;
    padding: 4rem;
    box-shadow: 6px 6px 10px 0px#678;
    border: 2px solid black;
}

.contact-map {
    width: 100%;
    height: auto;
    flex: 50%;

}

.contact-map iframe {
    width: 100%;
    height: 100%;


}

.contact-form {
    width: 100%;
    height: auto;
    flex: 50%;
    padding: 30px;
    text-align: center;

}

.contact-form2 {
    padding: 1rem;
    margin: 5rem;
}

.contact-form h1 {
    margin-bottom: 10px;
}

.contact-form-txt {
    width: 100%;
    height: 40px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
}

.contact-form-txt::placeholder {
    color: #aaa;
}

.contact-form-btn {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 50px;
    background: gray;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;

}

.contact-form-textarea {
    width: 100%;
    height: 12rem;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
}