.hero-container {
    /* --hero-animation-duration: 0.5s;
    --hero-animation-delay: 1s; */
    display: flex;
    margin-top: 13rem;
    margin-right: 2rem;
    margin-left: 2rem;
    justify-content: center;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}


@media(max-width: 1200px) {
    .hero-container {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 6rem;
    }
}

.hero-content {
    margin-top: 5%;
    /* animation-name: slide-from-left;
    animation-duration: var(--hero-animation-duration);
    animation-timing-function: ease-in;
    animation-delay: calc(var(--hero-animation-delay) * 2);
    animation-fill-mode: both;
    will-change: transform; */
}

.strong {
    color: #E3D5CA;
}

.hero-header {

    font-size: 3rem;
    white-space: nowrap;
    margin-bottom: 2rem;
}

.hero-subheader {

    font-weight: normal;
    font-size: 3rem;
}

@media(max-width: 768px) {
    .hero-header {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .hero-subheader {
        font-size: 2rem;
    }
}

@media(max-width: 400px) {
    .hero-header {
        font-size: 1.5rem;
        margin-bottom: 0.75rem;
    }

    .hero-subheader {
        font-size: 1.5rem;
    }
}

.hero-image {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    /* animation-name: slide-from-right;
    animation-duration: var(--hero-animation-duration);
    animation-timing-function: ease-in;
    animation-delay: var(--hero-animation-delay);
    animation-fill-mode: both;
    will-change: transform; */
}

.hero-icons {
    margin-top: 6rem;
    display: flex;
    gap: 40px;
    font-size: 2rem;
    justify-content: center;
}


/* @keyframes slide-down {
    0% {
        transform: translateY(-100);
    }

    100% {
        transform: translate(-100%);
    }
}

@keyframes scale-from-center {
    0% {
        transform: scale(0);
    }

    90% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
} */

/* @keyframes slide-from-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    90% {
        transform: translateX(2%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }

}

@keyframes slide-from-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    90% {
        transform: translateX(-2%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }

} */