:root {
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hs1(142, 90%, 61%);
}

.card-header {
    margin-top: 15rem;
    background: #F5EBE0;
    padding-bottom: 8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 4rem;
    border: 2px solid black;
    box-shadow: 6px 6px 10px 0px#678;

}

.card-text {
    font-size: 3rem;
    display: flex;
    justify-content: center;
}

.card-holder {
    display: flex;
    margin-top: 10rem;
    gap: 8rem;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    background-image: url("https://img-cdn.tnwcdn.com/image?fit=1200%2C1200&height=1200&url=https%3A%2F%2Fcdn0.tnwcdn.com%2Fwp-content%2Fblogs.dir%2F1%2Ffiles%2F2016%2F05%2Finstagram-logo.png&signature=1d814cfb43422a77c4376416944c3b1e");
    background-size: cover;
    display: flex;
    padding: 21rem 0px 0;
    width: auto;
    /*35 characters wide */
    border-radius: 1rem;
    overflow: hidden;
    transition: transform 500ms ease;
    box-shadow: 6px 6px 10px 0px#678;


}

.card2 {
    background-image: url("https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/d8992206-afdf-459a-80d7-a66541d35c18/degd2v3-e9fd95e6-5cea-41e3-a847-09dae2018f18.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2Q4OTkyMjA2LWFmZGYtNDU5YS04MGQ3LWE2NjU0MWQzNWMxOFwvZGVnZDJ2My1lOWZkOTVlNi01Y2VhLTQxZTMtYTg0Ny0wOWRhZTIwMThmMTguanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.0PCsP48Cr6LG0lTn8CA5yaHej1oxmWmg9JoJepW39sU");
    background-size: cover;
    display: flex;
    padding: 21rem 0px 0;
    width: auto;
    /*35 characters wide */
    border-radius: 1rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

.card3 {
    background-image: url("https://i.pinimg.com/originals/2d/1c/55/2d1c555e064de64b51e8ac16be67e3a3.png");
    background-size: cover;
    display: flex;
    padding: 21rem 0px 0;
    width: auto;
    /*35 characters wide */
    border-radius: 1rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

.card-content {
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(hsl(0 0% 0% / 0), hsl(20 0% 0% / 0.3) 20%,
            hsl(0 0% 0% / 1));
}



.card-title {
    position: relative;
    width: max-content;
    color: whitesmoke;
    font-size: 25px;
}


.card-title::after {
    content: '';
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: whitesmoke;
    transform-origin: left;
    transition: transform 500ms ease;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-body {
    color: whitesmoke;
    font-weight: bolder;
    font-size: 25px;


}


.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: black;
    background-color: #EDEDE9;
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.button:hover,
button:focus {
    background-color: var(--clr-neutral-100);
}

.cardAnimation {
    position: relative;
    opacity: 0;
}

.cardAnimation.active {
    opacity: 1;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@media(max-width: 1100px) {
    .card-holder {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

    }
}

@media(max-width: 450px) {
    .card-holder {
        margin-top: 3rem;

    }

    .card-text {
        font-size: 2rem;
    }

    .card {
        padding: 10rem 0px 0;

    }
}

@media (hover) {
    .card-content {
        transform: translateY(65%);
        transition: transform 500ms ease;
    }


    .card:hover,
    .card:focus-within .card-content {
        transform: scale(1.05);
    }

    .card:hover .card-content,
    .card:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card:focus-within .card-content {
        opacity: 0;

    }

    .card-content>*:not(.card-title) {
        opacity: 0;
        transition: opacity 500ms linear;
    }

    .card:hover .card-content>*:not(.card-title),
    .card:focus-within .card-content>*:not(.card-title) {

        opacity: 1;
        transition-delay: 1000ms;
    }

    .card-title::after {
        transform: scaleX(0);
    }

}



@keyframes fade-right {
    0% {
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0);

    }
}

@keyframes fade-left {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}