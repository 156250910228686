.about-header {
    font-size: 3rem;
    margin-top: 25rem;
    border: 2px solid black;
    background: #F5EBE0;
    padding: 4rem;
    box-shadow: 6px 6px 10px 0px#678;
}



.about-text {
    font-size: 3rem;
    display: flex;
    justify-content: center;

}



.about-container {
    margin-top: -1rem;
    display: flex;
    margin-right: 2rem;
    margin-left: 2rem;
    justify-content: center;
    animation: fadeUp .5s forwards;
    animation-delay: .5s;
}


@media(max-width: 1200px) {
    .about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .about-image {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .about-paragraph {
        margin-top: 0rem;
        line-height: normal;

    }

}


.about-paragraph {
    font-size: 2rem;
    display: flex;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    margin-top: 5rem;

}

@media(max-width: 768px) {
    .about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .about-image {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .about-paragraph {
        margin-top: 3rem;
        line-height: normal;
        font-size: 1.5rem;

    }

}


@media(max-width: 450px) {
    .about-container {
        margin: -3.5rem
    }

    .about-paragraph {
        margin-top: 3rem;
        margin-bottom: 3rem;
        font-size: 12pt;
        line-height: 1.3rem;

    }

    .about-text {
        font-size: 2rem;
        margin-bottom: 2rem
    }

    .about-header {
        margin-top: 15rem;
    }


}



.about-image {

    max-width: 40%;

}


/* @keyframes fadeUp {
    0% {
        transform: translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: translate(4rem);
    }
} */