.skills-container {
    margin-top: 15rem;
    border: 2px solid black;
    background: #F5EBE0;
    padding: 2rem;
    box-shadow: 6px 6px 10px 0px#678;
}

.skills-title {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 2rem;
}

.row {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 12px;

}

.skills-img {
    width: 100%;
    max-width: 100px;
    transition: all 300ms;
}

.skills:hover .skills-img {
    filter: brightness(80%);
    opacity: 0.86;
    transform: scale(0.9);
}

.skills {
    width: 25%;
    display: flex;
    justify-content: center;
    position: relative;
}

.skills-img--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 16px;
}

.skills-list {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
}

.skills-name {
    position: absolute;
    bottom: 0;
    transform: scale(0);
    transition: all 300ms;
    font-size: 20px;
    opacity: 0;
}

.skills:hover .skills-name {
    transform: scale(1);
    opacity: 1;
}

@media(max-width: 500px) {

    .skills-img {
        width: 4rem;
    }
}



@media(max-width: 450px) {


    .section__title {
        font-size: 2rem;
    }

    .skills-img {
        width: 4rem;
    }
}